.side-bar {
  position: fixed;
  width: 180px;
  border-right: 1px solid #ccc;
  height: 100%;
  box-shadow: 5px 0 2px -1px #ccc;
  flex-shrink: 0;
  top: 80px;
  visibility: visible;
  ul {
    li {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 15px 20px;
      cursor: pointer;
      &:nth-child(3) {
        border-top: 1px solid #ccc;
      }
      span {
        font-weight: bold;
      }
    }
    .active {
      background: #eaeaea;
    }
  }
}
.hidden {
  visibility: hidden;
}
