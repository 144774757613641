// @import '~normalize.css/normalize.css';
html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address,
del, em, img, small, strong, b, u, i, hr,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
button,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}
html {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th, td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
a img {
  border: none;
}
article, aside, details, figure, footer, header, menu, nav, section, summary {
  display: block;
}